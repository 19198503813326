import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ListFigureItem from "../components/ListFigureItem"
import SEO from "../components/seo"



export const query = graphql`
query {
    allSanityFigure(filter: {isSold: {eq: false}, showOnFrontPage: {eq: true}}) {
        nodes {
            id
            isComplete
            name
            image {
                crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                    asset {
                        _id
                }
            }
            price
            version
            year
            slug {
                current
            }
        }
    }
}
`


const IndexPage = (props) => {
    const {data} = props
    const figures = data.allSanityFigure.nodes
    

    return(
        <Layout>
            <SEO title="Actionfigurer fra barndommen!"/>
            <div className="w-full text-center">
                <StaticImage
                src="../images/mainimage.jpg"
                quality={95}
                width={1400}
                className="w-full"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Main image"
                />
            </div>
            <div className="container">
                <div className="mx-auto md:w-3/5 mt-10">
                    <h1 className="text-4xl text-bold mt-5 text-center">Velkommen til gijoe.no!</h1>
                    <p className="text-center mt-10">Du husker kanskje figurene fra barndommen? GI Joe, eller Action Force som det ofte ble kalt i Norge, fra 80- og 90-tallet er noe mange har et nostalgisk forhold til. Enten du er samler eller bare en som vil ha et kult minne fra barndommen, her finner du figurer til alle anledninger!</p>
                </div>
                <div className="mt-16">
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                        {figures.map(figure => (
                            <ListFigureItem figure={figure} key={figure.id} />                                                          
                        ))}
                    </div>
                </div>
            </div>


        </Layout>
        )}

export default IndexPage
